import { classNames } from "~/utils/etc";
import { Spacer } from "~/widgets/spacer";
import HubbleImage from "~/widgets/hubble_image";
import { Accessor, JSX, Match, Switch } from "solid-js";
import { VoucherGenerationBrandCard } from "../brand_l2/voucher_generation_brand_card";
import { GeneratedVoucherDetails } from "./voucher_details";
import { toRupees } from "~/utils/number";
import styles from "./gift_container.module.scss";
import {
  BrandDetailResponse,
  VoucherDetailResponse,
} from "~/server/types/gift";

export function BorderGradient({ children }: { children?: JSX.Element }) {
  return (
    <div class={classNames(styles.rotatingGradientParent, "w-full")}>
      {children}
      <div class={classNames(styles.rotatingGradientContent)}></div>
    </div>
  );
}

export type CardProps = {
  brand: BrandDetailResponse;
  voucher?: VoucherDetailResponse;
  amount: number;
  isPreview?: boolean;
  getGiftVoucherSectionComponent?: () => JSX.Element;
  isExpired?: boolean;
  highlightCardDetails?: Accessor<boolean>;
  defaultEventProps?: Record<string, any>;
};

export function Card(props: CardProps) {
  return (
    <div class=" flex w-full  flex-col items-center rounded-2xl border-[1px] border-baseSecondaryDark  bg-black">
      <Spacer height={6} />
      <div class="w-full px-2">
        <Switch>
          <Match when={props.brand.heroImageUrl}>
            <HubbleImage
              class="aspect-[541/316] w-full rounded-lg  "
              src={props.brand.heroImageUrl!}
              alt="Gift card image"
            />
          </Match>
          <Match when={true}>
            <VoucherGenerationBrandCard
              showAmount={false}
              amount={props.amount}
              plainLogoUrl={props.brand.plainLogoUrl ?? ""}
              cardBackgroundColor={props.brand.cardBackgroundColor ?? "#000000"}
              showHubbleLogo={true}
              borderClass="rounded-lg"
            />
          </Match>
        </Switch>
      </div>
      <Spacer height={24} />
      <CardValue isExpired={props.isExpired} />
      <Switch>
        <Match when={props.voucher}>
          <div
            class="w-full p-5"
            classList={{
              [styles.highlightVoucher]: props.highlightCardDetails?.(),
            }}
          >
            <GeneratedVoucherDetails
              isPreview={props.isPreview ?? false}
              voucher={props.voucher!}
              brand={props.brand}
              defaultEventProps={props.defaultEventProps}
            />
          </div>
        </Match>
        <Match when={props.getGiftVoucherSectionComponent}>
          {props.getGiftVoucherSectionComponent?.()}
        </Match>
        <Match when={true}>
          <Spacer height={20} />
        </Match>
      </Switch>
    </div>
  );

  function CardValue(cardValueProps: { isExpired?: boolean }) {
    return (
      <>
        <p
          class="font-['Inter'] text-[10px] font-medium tracking-[1.8px]"
          classList={{
            "text-baseSecondaryMedium": cardValueProps.isExpired,
          }}
        >
          GIFT CARD WORTH
        </p>
        <p
          class="font-['Inter']  text-[36px] font-semibold tracking-[-0.72px]"
          classList={{
            "text-baseSecondaryMedium": cardValueProps.isExpired,
          }}
        >
          {toRupees(props.amount)}
        </p>
      </>
    );
  }
}
